exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-data-collection-js": () => import("./../../../src/pages/data-collection.js" /* webpackChunkName: "component---src-pages-data-collection-js" */),
  "component---src-pages-document-generation-js": () => import("./../../../src/pages/document-generation.js" /* webpackChunkName: "component---src-pages-document-generation-js" */),
  "component---src-pages-document-management-js": () => import("./../../../src/pages/document-management.js" /* webpackChunkName: "component---src-pages-document-management-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-license-agreement-rus-js": () => import("./../../../src/pages/license-agreement-rus.js" /* webpackChunkName: "component---src-pages-license-agreement-rus-js" */),
  "component---src-pages-organization-structure-js": () => import("./../../../src/pages/organization-structure.js" /* webpackChunkName: "component---src-pages-organization-structure-js" */),
  "component---src-pages-tech-support-js": () => import("./../../../src/pages/tech-support.js" /* webpackChunkName: "component---src-pages-tech-support-js" */),
  "component---src-pages-workflow-automation-js": () => import("./../../../src/pages/workflow-automation.js" /* webpackChunkName: "component---src-pages-workflow-automation-js" */)
}

